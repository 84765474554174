import React from "react";

import styles from "./styles.module.css";


const Tiles = (props) => {
    if (!props.children) {
        return null;
    }

    if (props.isMobile) {
        return (
            <div className={styles.tilesMobile}>
                {props.children}
            </div>
        );
    }

    const col1 = [];
    const col2 = [];

    for (let i = 0; i < props.children.length; i += 1) {
        if (i % 2 === 0) {
            col1.push(props.children[i]);
        } else {
            col2.push(props.children[i]);
        }
    }

    return (
        <div className={styles.tilesDesktop}>
            <div className={styles.tilesDesktopCol}>
                {col1}
            </div>
            <div className={styles.tilesDesktopCol}>
                {col2}
            </div>
        </div>
    );
};

Tiles.defaultProps = {
    isMobile: false,
    children: null,
};

export default Tiles;
