export const getWord = (words, wordName) => {
    const wordNameLC = wordName.toLowerCase();

    let word = words[wordName];

    if (!word && words[wordNameLC]) {
        word = words[wordNameLC];
    }

    return word;
};

export const groupWordDefinitionsByPos = (definitions) => {
    const ps = [];
    const grouped = {};

    definitions.forEach((d) => {
        if (ps.indexOf(d.pos) === -1) {
            ps.push(d.pos);
            grouped[d.pos] = [];
        }

        grouped[d.pos].push(d);
    });

    const ret = [];

    ps.forEach((pos) => {
        ret.push([pos, grouped[pos]]);
    });

    return ret;
};

export default {
    getWord,
    groupWordDefinitionsByPos,
};
